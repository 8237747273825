export class SecondaryUser {
  id?: number; //not sure if needed
  pk?: number;
  first_name?: string;
  last_name?: string;
  email?: string;
  email2?: string;
  dob?: Date;
  address?: string;
  address2?: string;
  city?: string;
  parish?: string;
  postal_code?: string;
  phone?: string;
  trn?: string;
  pick_up_parish?: string;
  role?: any;
  verification_file?: string;
  password1?: string;
  password2?: string;
  authorized_pickup?: boolean;
}
