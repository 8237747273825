

















































































































































































































import { ErrorManager } from '@/models/error';
import { SecondaryUser } from '@/models/secondaryUser';
import { FormRules } from '@/utils/formRules';
import Vue from 'vue';
import { mapActions } from 'vuex';
export default Vue.extend({
  name: 'SecondaryUserForm',
  mixins: [FormRules],

  data() {
    return {
      dialog: false,
      valid: false,
      fromDateMenu: false,
      loading: false,

      fromDateVal: new Date().toISOString().slice(0, 10),
      errorMessage: '',
      newUser: new SecondaryUser(),
    };
  },

  computed: {
    disableBtn(): boolean {
      return (
        this.valid &&
        this.newUser.password1 == this.newUser.password2 &&
          this.newUser.password1 != '' &&
          this.newUser.password2 != '' &&
        this.newUser.email == this.newUser.email2 &&
          this.newUser.email != '' &&
          this.newUser.email2 != ''
      );
    },

    dateValue(): any {
      return this.fromDateVal;
    },

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    },
  },

  methods: {
    ...mapActions({
      createSecondaryUser: 'secondaryuser/createSecondaryUser',
    }),

    open() {
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.errorMessage = '';
    },

    addSecodaryUser() {
      this.loading = true;
      this.newUser.dob = this.dateValue;
      this.newUser.role = 'C';
      this.newUser.authorized_pickup = true;

      this.createSecondaryUser(this.newUser)
        .then(() => {
          this.close();
        })
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
