





























































































































































import { ErrorManager } from '@/models/error';
import { SecondaryUser } from '@/models/secondaryUser';
import { FormRules } from '@/utils/formRules';
import { cloneDeep } from 'lodash';
import Vue from 'vue';
import { mapActions } from 'vuex';
export default Vue.extend({
  name: 'SecondaryUserForm',
  mixins: [FormRules],

  data() {
    return {
      dialog: false,
      valid: false,
      fromDateMenu: false,
      fromDateVal: new Date().toISOString().slice(0, 10),
      errorMessage: '',
      loading: false,
      secondaryUser: new SecondaryUser(),
    };
  },

  computed: {
    dateValue(): any {
      return this.fromDateVal;
    },

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    },
  },

  methods: {
    ...mapActions({
      updateSecondaryUser: 'secondaryuser/updateSecondaryUser',
    }),

    open(secondaryUser: SecondaryUser) {
      this.dialog = true;
      this.secondaryUser = cloneDeep(secondaryUser);
    },

    close() {
      this.dialog = false;
      this.errorMessage = '';
    },

    editSecodaryUser() {
      this.secondaryUser.dob = this.dateValue;
      this.loading = true;
      this.updateSecondaryUser(this.secondaryUser)
        .then(() => {
          this.close();
        })
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
