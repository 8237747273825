










































































































































import { ErrorManager } from '@/models/error';
import { SecondaryUser } from '@/models/secondaryUser';
import Vue from 'vue'
import { mapActions, mapState } from 'vuex';
import SecondaryUserEditForm from './SecondaryUserEditForm.vue';
import SecondaryUserForm from './SecondaryUserForm.vue'
export default Vue.extend({
    name: 'SeccondaryUserList',
    components: {
        SecondaryUserForm,
        SecondaryUserEditForm
    },
    data(){
        return{
          search: '',
          errorMessage: '',
          loading: false
        }
    },
    computed: {
      ...mapState('secondaryuser', ['secondaryUser']),

        errorOccurred(): boolean {
            return this.errorMessage.length > 0;
        }
    },

    methods: {
      ...mapActions({
            fetchSecondaryUsers: 'secondaryuser/fetchSecondaryUsers'
        }),
        openDialog(){
            (this as any).$refs.secondaryuserform.open();
        },

        openEditForm(secondaryUser: SecondaryUser){
            (this as any).$refs.secondaryusereditform.open(secondaryUser);
        },

        filterSecondaryUserObject(queryText: string){
            const filter = this.secondaryUser.filter((element: SecondaryUser) => {
              return ( element.first_name!.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 || 
              element.last_name!.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1);
              });
            return filter;
         },
    },
    mounted(){
        this.loading = true;
        this.fetchSecondaryUsers().catch((error: any) => {
            this.errorMessage = ErrorManager.extractApiError(error);
        }).finally(() => {this.loading = false});
    }
})
