






import Vue from 'vue';
import SecondaryUserList from '../../components/secondaryuser/SecondaryUserList.vue';
export default Vue.extend({
    name: 'SecondaryUserPage',
    components: {
        SecondaryUserList
    },
    metaInfo(){
      return  {
      title: "Secondary Users - Our Nation Express"
    };
    }
})
